<template>
  <NuxtLayout name="base">
    <div class="flex flex-col min-h-dvh">
      <div class="flex-grow min-w-0">
        <slot />
      </div>
      <AppFooter />
    </div>
  </NuxtLayout>
</template>
